/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


         // load your Google fonts here
         // ---------------------------
        
        WebFontConfig = {
          google: { families: [ 'Open+Sans:300italic,400italic,700italic,400,300,700:latin' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        // variables globales
        // ------------------
        var gridFloatBreakpoint = 992;  // less media queries : @grid-float-breakpoint (992px)
        var scrollTop_value = 0;
        

        // navbar-collapse auto height (when openned)
        $(window).load(function() {
          var window_height = $(window).height();
           // console.log('window height : ' + window_height + 'px');
           // 992 is the breakpoint
           if ($(window).width() < gridFloatBreakpoint) {
              scrollTop_value = 0; // needed for the navbar shrink
              // $('#main-menu-collapse.navbar-collapse').css('min-height', window_height+'px');
           }
           else {
              scrollTop_value = 60;
              $('#main-menu-collapse.navbar-collapse').css('min-height', '100%');
           }
        });
        
        // navbar shrink on scroll
        $(window).scroll(function() {
          if ($(document).scrollTop() >= scrollTop_value) {
            $('body').addClass('shrink');
          } else {
            $('body').removeClass('shrink');
          }
        });

        // navbar search in dropdown
        $('#open-dropdown-search').click(function () {
          setTimeout(function(){$('#nav-search-input').focus();},0);
        });
        $('#open-dropdown-search input[type=search]').click(function(e){
            e.stopPropagation();
        });

        // bootstrap-select component
        // the selectpicker class or ID we want to customize
        $('.gmw-form select').selectpicker();
        $('.gmw-form select').selectpicker('setStyle', 'btn-success');
        $('.gmw-form .gmw-unit-distance-wrapper select').selectpicker('setStyle', 'btn-block btn-lg', 'add');


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Carousel script 
        $('.carousel').carousel({
          interval: 4000
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Eqentrepreneurs page, grid filter, with bourbon Saas library
    'entrepreneurs': {
      init: function() {
        // JavaScript to be fired on the "Equipes" page
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
